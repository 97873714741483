.highlightContent{
    display: inline-flex;
    align-items: center;
    width:100%;
}

.highlightText{
    padding:6px;
    width:100%;
}

.highlightText:hover{
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    padding:5px;
}

.highlightButton{
    position: absolute;
    right:-95px;
}

.highlightableInitCounter{
    text-align: right;
}