.accountCenter{
    background-color: #F4F5F7;
    padding: 30px;
    width: 100%;
    height: min-content;
    text-align: left;
}

.accountHeader{
    margin-top: 30px;
    margin-bottom: 10px;
}

.accountSection{
    text-align: left;
}

.accountInput{
    margin-bottom: 20px;;
    margin-top: 20px;;
}
  
.accountBilling{
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
}

.accountButtons{
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
}

.accountSingleButton{
    margin:20px;
}

.accountTableSub{
    
}

.accountTableHeader{

}

.accountTableRow{

}

.accountTableColumnHeader{
    
}

.accountManageSub{
    margin-top: 20px;
    margin-bottom: 20px;
}

.accountSubheader{
    margin-bottom:20px;
}