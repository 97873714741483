.panelStoriesActions{
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom:40px;
  }

.panelStoriesContent{

}
  
.panelStoriesDisplay{

}

.panelStoriesEmpty{
    text-align: center;
    padding-top:50px;
    padding-bottom:50px;
}

.large-emoji{
  font-size: 20px;
}

.panelStoriesDropdownImage{
  border-style: solid;
  border-color:#aaaaaa;
  padding-left:5px;
  padding-top:5px;
  padding-bottom:1px;
  padding-right:5px;
  border-radius: 5px;
  margin-right:5px;
}