.contextMain{
    background-color: #F4F5F7;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:800px;
}

.contextCenter{
    background-color: #F4F5F7;
    padding: 30px;
    width: 100%;
    height: min-content;
    text-align: left;
}

.contextInputSection{
    display: flex;
    flex-direction: column;
    width:100%;
}

.contextHeader{
    margin-top: 30px;
    margin-bottom: 30px;
    color: #1f496f;
}

.contextSubHeader{
    margin-bottom:10px;
}

.contextInstructions{
    margin-left:20px;
}

.contextInput{
    text-align: left;
    margin: 20px;
}

.contextCounter{
    text-align: right;
}

.contextButton{

}

.contextNote{
    text-align: left;
    margin-bottom: 20px;
}

.contextSelection{
    display: flex;
}

.contextSelectionDropdown{

}

.contextSelectionButton{
    padding-left: 10px;
}

.contextName{
    display:flex;
    align-items: center;
    margin-left:20px;
    margin-bottom:20px;
}

.contextNameInput{
    width:300px;
}

.contextNameLabel{
    padding-right:10px;
}