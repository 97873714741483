.panelPrdContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .panelPrdActions{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .panelPrdContentDisplay{
  
  }

  .panelPrdEmpty{
    text-align: center;
    padding-bottom:50px;
  }

  .large-emoji{
    font-size: 20px;
  }

  .panelPrdQuickActions{
    text-align: right;
    width:30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .panelDropdownImage{
    border-style: solid;
    border-color:#aaaaaa;
    padding-left:5px;
    padding-top:5px;
    padding-bottom:1px;
    padding-right:5px;
    border-radius: 5px;
    margin-right:5px;
  }
