html, body{
  margin:0;
  padding:0;
  width:100%;
  height:100%;
  background-color: #ffffff;
  font-family: 'Inter', sans-serif !important;
  color: #1f496f;
}

h1, h2, h3, h4, h5, h6, p, button, .ui {
  font-family: 'Inter', sans-serif !important;
  color: #1f496f;
}

a {
  color: #618FA8;
}

.App {
  text-align: center;
  font-family: Poppins, sans-serif;
  background-color: #F4F5F7;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #618FA8; */
  background-color: white;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-content: center;
  color:#1f496f;
}

.headerTitle{
  font-weight: bold;
  margin-left:20px;
}

.headerMenusRight{
  display: flex;
  align-items: center;
}

.headerFeedback{
  margin:10px;
  padding-top:10px;
  cursor:pointer;
}

.headerUserMenu {
  font-size: 13px;
  display: flex;
  margin:10px;
}

.headerUserAccount{
  margin:10px;
}

.headerUserAccountType{

}

.headerUserConsumption{
  margin:10px;
  font-size: 12px;
  margin-right:20px;
}

.headerUserAccountLogin{
  padding-top:10px;
  padding-bottom:10px;
  margin-right:20px;
}

.content {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: white;
}

.history{
  background-color: white;
  width: 15%;
  text-align: left;
  padding-left:10px;
  padding-top:20px;
  padding-bottom:20px;
}

.historyHeader{
  margin-left:10px;
}

.historyList{
  padding-top:5px;
  padding-left:10px;
}

.historyListEmpty{
  padding-left:5px;
  padding-top: 10px;
}

.historySearch{
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
}

.menu{
  background-color: blue;
  width:fit-content;
  text-align: left;
}

.submenu{
  font-size: smaller;
}

.main{
  background-color: #F4F5F7;
  width: 70%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  border-radius: 15px;
  height: min-content;
  padding-bottom: 30px;
}

.panel{
  text-align: left;
}

.inputHeader{
  display: flex;
  margin-bottom:15px;
}

.inputHeaderSub{
  margin-right:10px;
}

.inputsection{
  margin-bottom:50px;
}

.inputInitCounter{
  text-align: right;
  margin-right:20px;
}

.inputPopup{
  font-family: 'Inter', sans-serif !important;
  color: #1f496f;
}

.inputInitiativeText{
  resize: none;
}

.inputInitiativeTextInstruction{
  margin-left:0px;
  margin-bottom: 10px;
  text-align: left;
}

.inputSectionTextArea{
  display:flex;
  align-items: flex-end;
}

.inputSectionTextAreaClear{
  position: relative;
  left: -20px;
  top: -10px;
}

.inputSectionTextAreaClear:hover{
  cursor: pointer;
}

.results{

}

.popover-class{
  
}

.inputSectionSubTextCheckbox{
}

.inputSectionSubText{
  margin-top:5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
