.initMain{
    background-color: #f4f5f7;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:800px;
}

.initCenter{
    background-color: #f4f5f7;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 50%;
    height: 600px;
    justify-content: space-between;
}

.initInputSection{
    display: flex;
    flex-direction: column;
    width:100%;
}

.initHeader{
    margin-bottom: 30px;
}

.initSubHeader{
    margin-left:10px;
}

.initInstructions{
    margin-left:20px;
    text-align: left;
}

.initInput{
    text-align: left;
    margin: 20px;
}

.initCounter{
    text-align: right;
}

.initButton{

}

.initNote{
    text-align: left;
    margin-bottom: 20px;
}