.integrationCenter{
  background-color: #F4F5F7;
  padding: 30px;
  width: 100%;
  height: min-content;
  text-align: left;
}

.integrationHeader{
  margin-top: 30px;
  margin-bottom: 10px;
}

.integrationSection{
    text-align: left;
  }
  
  .integrationSubheader{
  
  }

  .integrationColHeader{
    font-weight: bolder;
    background-color:rgb(232, 232, 232);
    padding:10px;
    width:200px;
  }
  
  .integrationJira{
    margin-top:10px;
    margin-bottom:20px;
  }

  .integrationJiraButton{
    margin-top:20px;
  }
  
  .integrationNotion{
    margin-top:20px;
    margin-bottom:20px;
  }
  
  .integrationConfluence{
    margin:5px;
  }

  .integrationNeedSubscription{
    padding:30px;
    text-align: center;
  }