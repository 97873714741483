.loginMain{
    background-color: #e9e3e3e7;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:800px;
}

.loginCenter{
    background-color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 50%;
    height: 250px;
    justify-content: space-between;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    border: 1px solid #ddd;
    border-radius: 8px;
}

.loginError{
    color: red;
}

.loginHeader{

}

.loginInput{
    width:50%;
}

.loginButton{

}

.loginNote{

}