.userstory_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.userstory_body{
    width:85%;
}

.userstory_quickaction{
    margin-top:9px;
}

.userStoryDropdownImage{
    border-style: solid;
    border-color:#aaaaaa;
    padding-left:5px;
    padding-top:5px;
    padding-bottom:1px;
    padding-right:5px;
    border-radius: 5px;
    margin-right:5px;
  }